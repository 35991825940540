import Api from './api';
import { SourceRequest, Source } from '@/models/source';
import { VariableResults } from '@/models/variable';
import { ServerResponse } from '@/models/response';

export default class SourceApi extends Api {
  public findAll(): Promise<ServerResponse<Source[]>> {
    const url = '/api/source';

    return this.get<ServerResponse<Source[]>>(url).then((response) => {
      const { data } = response;

      return data;
    });
  }

  public create(source: SourceRequest): Promise<ServerResponse<null>> {
    const url = '/api/source';

    return this.post<SourceRequest, ServerResponse<null>>(url, source).then((response) => {
      const { data } = response;

      return data;
    });
  }

  public remove(sourceId: number): Promise<ServerResponse<null>> {
    const url = `/api/source/${sourceId}`;

    return this.delete<ServerResponse<null>>(url).then((response) => {
      const { data } = response;

      return data;
    });
  }

  public query(sourceUUID: string, query: string, encrypted: boolean, variables?: VariableResults[]): Promise<ServerResponse<any>> {
    const url = `/api/source/${sourceUUID}/query?encrypted=${encrypted}`;

    if (this.httpController) {
      console.log('ABORT');
      this.httpController.abort();
    }

    this.httpController = this.getHttpController();

    return this.post<{ query: string, variables?: VariableResults[] }, ServerResponse<any>>(
      url,
      {
        query,
        ...(variables && { variables }),
      },
      {
        signal: this.httpController.signal,
      },
    ).then((response) => {
      this.httpController = null;

      const { data } = response;

      return data;
    });
  }
}
