
























































import { Vue, Component } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import DashboardApi from '@/services/dashboard';
import SourceApi from '@/services/source';
import { charts } from '@/components/charts/charts-config';
import ChartContainer from '@/components/charts/ChartContainer.vue';
import { ChartConfig } from '@/models/charts-config';
import {
  Panel,
  ChartPreview,
  Chart,
  EnumPanelType,
} from '@/models/dashboard';
import { Source } from '@/models/source';
import { EnumGlobalModal } from '@/models/global-modals';
import { calculatePositionForNewPanel } from '@/helpers/grid';
import store from '@/store';

enum EnumMode {
  ADD_CHART = 'ADD_CHART',
  EDIT_CHART = 'EDIT_CHART',
}

const formComponents = charts
  .reduce((obj, item) => Object.assign(obj, { [`${item.type}_form`]: () => item.formComponent }), {});

@Component({
  components: {
    // ...chartComponents,
    ...formComponents,
    ChartContainer,
  },
})
export default class AddChart extends Vue {
  dashboardApi = new DashboardApi();

  sourceApi = new SourceApi();

  mode: EnumMode = '' as EnumMode;

  dashboardId: number | null = null;

  availableSources: Source[] | null = null;

  selectedSource: Source | null = null;

  // query = '';

  queryResult: any | null = null;

  chartsConfig: ChartConfig[] = charts;

  chart: ChartPreview<any> | Chart<any> | null = null;

  panelId: number | null = null;

  isEditingChartProperties = false;

  // created(): void {
  //   store.dispatch('setLoading', true);

  //   this.mode = this.$route.name === 'AddChart' ? EnumMode.ADD_CHART : EnumMode.EDIT_CHART;
  //   console.log('mode', this.mode);

  //   this.dashboardId = parseInt(this.$route.params.id as string, 10);

  //   this.sourceApi.findAll().then((response) => {
  //     if (response.success) {
  //       this.availableSources = response.result;
  //     } else {
  //       console.log('err', response);
  //     }
  //   }).catch((err) => {
  //     console.log('err', err);
  //   }).finally(() => {
  //     store.dispatch('setLoading', false);
  //   });
  // }

  async created(): Promise<void> {
    try {
      store.dispatch('setLoading', true);
      this.mode = this.$route.name === 'AddChart' ? EnumMode.ADD_CHART : EnumMode.EDIT_CHART;

      this.dashboardId = parseInt(this.$route.params.id as string, 10);
      this.availableSources = (await this.sourceApi.findAll()).result;

      if (this.mode === EnumMode.ADD_CHART) {
        this.chart = {
          uuid: '',
          sourceUUID: '',
          type: '',
          query: '',
          chartData: {},
        };
      }

      if (this.mode === EnumMode.EDIT_CHART) {
        this.panelId = parseInt(this.$route.params.panelId as string, 10);
        this.chart = (await this.dashboardApi.findOne(this.dashboardId, false)).result.panels!.find((x) => x.id === this.panelId)!.data as Chart<any>;
        this.selectedSource = this.availableSources.find((x) => x.uuid === this.chart?.sourceUUID) || null;
      }

      store.dispatch('setLoading', false);
    } catch (err) {
      console.log('err');
    }
  }

  // get currentFormComponent() {
  //   return Vue.component(
  //     `${this.selectedChartType}_form_component`,
  //     () => this.chartsConfig.find((x) => x.type === this.selectedChartType)!.formComponent,
  //   );
  // }

  // get currentChartComponent() {
  //   return Vue.component(
  //     `${this.selectedChartType}_chart_component`,
  //     () => this.chartsConfig.find((x) => x.type === this.selectedChartType)!.chartComponent,
  //   );
  // }

  get tableFields() {
    return Object.keys(this.queryResult[0]).map((x) => ({ text: x, value: x }));
  }

  executeQuery(): void {
    this.sourceApi.query(this.selectedSource!.uuid, this.chart!.query, false).then((response) => {
      if (response.success) {
        this.queryResult = response.result;
      } else {
        console.log('err', response);
      }
    });
  }

  onChartDataChange(data: any): void {
    this.chart = {
      sourceUUID: this.selectedSource!.uuid,
      type: this.chart!.type,
      query: this.chart!.query,
      chartData: data,
    };
  }

  async submitChart(): Promise<void> {
    const dashboard = (await this.dashboardApi.findOne(this.dashboardId!, false)).result;

    if (this.mode === EnumMode.ADD_CHART) {
      const panel: Panel = {
        // eslint-disable-next-line
        id: dashboard.panels && dashboard.panels.length ? Math.max.apply(Math, dashboard.panels.map((x) => x.id)) + 1 : 1,
        type: EnumPanelType.CHART,
        data: {
          ...this.chart,
          uuid: uuidv4(),
        },
        position: calculatePositionForNewPanel(dashboard),
      };

      dashboard.panels = dashboard.panels
        ? dashboard.panels.concat([panel])
        : [panel];
    }

    if (this.mode === EnumMode.EDIT_CHART) {
      const panel: Panel = {
        ...dashboard.panels!.find((x) => x.id === this.panelId)!,
        // data: this.chart!,
        data: {
          ...dashboard.panels!.find((x) => x.id === this.panelId)!.data,
          ...this.chart,
        },
      };

      dashboard.panels = dashboard.panels!
        .filter((x) => x.id !== this.panelId)
        .concat([panel]);
    }

    this.dashboardApi.update(this.dashboardId!, dashboard).then((response) => {
      if (response.success) {
        console.log('success', response);
        this.$router.push(`/dashboard/${this.dashboardId}`);
      } else {
        console.log('err', response);
      }
    });
  }
}
