// todo: maybe remove provider info
enum EnumProvider {
  APEX_CHARTS = 'APEX_CHARTS',
  MATERIAL_DESIGN = 'MATERIAL_DESIGN',
  LEAFLET = 'LEAFLET',
}

interface ChartOptions {
  defaultSize: {
    width: number,
    height: number,
  }
}

interface ChartConfig {
  type: string,
  provider: EnumProvider,
  formComponent: Promise<typeof import('*.vue')>,
  chartComponent: Promise<typeof import('*.vue')>,
}

export { EnumProvider, ChartOptions, ChartConfig };
