















import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import ChartBase from '@/components/charts/ChartBase';
import { charts } from '@/components/charts/charts-config';
// import DashboardApi from '@/services/dashboard';
import SourceApi from '@/services/source';
import { interpolateVariablesIntoQuery } from '@/helpers/variable';
import { Chart, ChartPreview } from '@/models/dashboard';
import { VariableResults } from '@/models/variable';
import { EnumRole } from '@/models/user';

const chartComponents = charts
  .reduce((obj, item) => Object.assign(obj, { [`${item.type}_chart`]: () => item.chartComponent }), {});

@Component({
  components: {
    ...chartComponents,
  },
})
export default class ChartContainer extends Vue {
  @Prop() queryResult!: any;

  @Prop({ required: true }) chart!: Chart<any> | ChartPreview<any>;

  // dashboardApi = new DashboardApi();
  sourceApi = new SourceApi();

  // dashboardId: number | null = null;

  qResult: any | null = null;

  created(): void {
    if (this.queryResult) {
      console.log('hey there!!');
      this.qResult = this.queryResult;
    } else {
      this.getQueryResult(this.chart.query);
    }
  }

  @Watch('$store.getters.getVariables', { deep: true })
  onVariablesChange(variables: VariableResults[]): void {
    console.log('hey! variables changed!', variables);

    this.getQueryResult(this.chart.query, variables);
  }

  getQueryResult(query: string, variables?: VariableResults[]): void {
    const encrypted = query.startsWith('encrypted_');

    this.sourceApi.query(this.chart.sourceUUID, query, encrypted, variables).then((response) => {
      this.qResult = response.result;
      console.log('queryresult', this.qResult);
    });
  }

  onPanelResize(): void {
    console.log('hey, resized!');
    (this.$refs.chart! as ChartBase).onChartResize();
  }
}
