import { DashboardConfiguration, PanelPosition } from '@/models/dashboard';
import { chartOptions } from '@/components/charts/charts-config';

// todo
/* eslint-disable */

const calculatePositionForNewPanel = (dashboard: DashboardConfiguration): PanelPosition => {
  const w = chartOptions.defaultSize.width;
  const h = chartOptions.defaultSize.height;

  const x = 0;

  if (!dashboard.panels || !dashboard.panels.length) {
    const y = 0;
    return { x, y, w, h };
  }

  const maxY = dashboard.panels
    .map((x) => x.position.y)
    .reduce((t, c) => {
      return c > t ? c : t;
    }, -1);

  const maxHforMaxY = dashboard.panels
    .filter((x) => x.position.y === maxY)
    .map((x) => x.position.h)
    .reduce((t, c) => {
      return c > t ? c : t;
    }, -1);

  const y = maxY + maxHforMaxY + 1;

  return { x, y, w, h };
}

export { calculatePositionForNewPanel };
