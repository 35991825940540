import { ChartConfig, ChartOptions, EnumProvider } from '@/models/charts-config';

const chartOptions: ChartOptions = {
  defaultSize: {
    width: 3,
    height: 8,
  },
};

const charts: ChartConfig[] = [
  {
    type: 'Table',
    provider: EnumProvider.MATERIAL_DESIGN,
    formComponent: import(/* webpackPrefetch: true */ './table/FormTable.vue'),
    chartComponent: import(/* webpackPrefetch: true */ './table/ChartTable.vue'),
  },
  {
    type: 'Card',
    provider: EnumProvider.MATERIAL_DESIGN,
    formComponent: import(/* webpackPrefetch: true */ './card/FormCard.vue'),
    chartComponent: import(/* webpackPrefetch: true */ './card/ChartCard.vue'),
  },
  {
    type: 'Bar',
    provider: EnumProvider.APEX_CHARTS,
    formComponent: import(/* webpackPrefetch: true */ './bar/FormBar.vue'),
    chartComponent: import(/* webpackPrefetch: true */ './bar/ChartBar.vue'),
  },
  {
    type: 'Line',
    provider: EnumProvider.APEX_CHARTS,
    formComponent: import(/* webpackPrefetch: true */ './line/FormLine.vue'),
    chartComponent: import(/* webpackPrefetch: true */ './line/ChartLine.vue'),
  },
  {
    type: 'Pie',
    provider: EnumProvider.APEX_CHARTS,
    formComponent: import(/* webpackPrefetch: true */ './pie/FormPie.vue'),
    chartComponent: import(/* webpackPrefetch: true */ './pie/ChartPie.vue'),
  },
  {
    type: 'Radial',
    provider: EnumProvider.APEX_CHARTS,
    formComponent: import(/* webpackPrefetch: true */ './radial/FormRadial.vue'),
    chartComponent: import(/* webpackPrefetch: true */ './radial/ChartRadial.vue'),
  },
  {
    type: 'Map',
    provider: EnumProvider.LEAFLET,
    formComponent: import(/* webpackPrefetch: true */ './map/FormMap.vue'),
    chartComponent: import(/* webpackPrefetch: true */ './map/ChartMap.vue'),
  },
  {
    type: 'KPI-Radial',
    provider: EnumProvider.APEX_CHARTS,
    formComponent: import(/* webpackPrefetch: true */ './kpi-radial/FormKpiRadial.vue'),
    chartComponent: import(/* webpackPrefetch: true */ './kpi-radial/ChartKpiRadial.vue'),
  },
];

export { chartOptions, charts };
