// todo
/* eslint-disable */

import { Variable } from './variable';

enum EnumPanelType {
  CHART = 'CHART',
  HTML_BLOCK = 'HTML_BLOCK',
  FILTER = 'FILTER',
}

interface ChartPreview<T> {
  sourceUUID: string,
  type: string,
  query: string,
  chartData: T,
}

interface Chart<T> extends ChartPreview<T> {
  uuid: string,
}

interface HtmlBlockPreview {
  // todo: if needed
}

interface HtmlBlock {
  // todo
}

interface FilterPreview {
  // todo: if needed
}

interface Filter {
  variableID: number,
}

interface Panel {
  id: number,
  type: EnumPanelType,
  data: Chart<any> | HtmlBlock | Filter,
  position: PanelPosition,
}

interface PanelPosition {
  x: number,
  y: number,
  w: number,
  h: number,
}

interface PanelPositionWithIndex extends PanelPosition {
  i: number,
}

interface DashboardBase {
  name: string,
  description: string,
  // todo: move in one object
  icon: string,
  iconBgColor: string,
  iconColorType: 'light' | 'dark',
}

interface DashboardConfigurationRequest extends DashboardBase {
  isPublic: boolean,
}

interface DashboardLite extends DashboardBase {
  id: number,
  chartsCount: number,
}

interface DashboardConfiguration extends DashboardBase {
  id: number,
  isPublic: boolean,
  variables?: Variable[],
  panels?: Panel[],
}

export {
  EnumPanelType,
  DashboardConfigurationRequest,
  DashboardLite,
  DashboardConfiguration,
  PanelPosition,
  PanelPositionWithIndex,
  Panel,
  ChartPreview,
  Chart,
};
